import React from 'react'
import { useTranslation } from 'react-i18next'

/* configs */
import clsx from 'clsx'
import { menuItems } from './configs'

/* styles */
import classes from './index.module.scss'

const NavLinks = ({ onItemClick, isHeader }) => {
  const { t } = useTranslation('header_volunteering')

  const renderNavLink = menuItems.map((menuItem) => (
    <li role="button" className={classes.navlinksListItem} key={`${menuItem.id} - ${menuItem.label}`} onClick={onItemClick}
    aria-label={t(menuItem.label)}
    >
      <a href={`${menuItem.path}`} className={classes.navlinksListItemLink} target={menuItem.path === '/' ? '_self' : '_blank'} rel="noreferrer">
        {t(menuItem.label)}
      </a>
    </li>
  ))

  return (
    <ul className={clsx(classes.navlinksList, { [classes.mobileLinks]: isHeader })}>
      {renderNavLink}
    </ul>
  )
}

export default NavLinks
